import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type { PlayerGamesLogTable } from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

import parseBaseStats from './parseBaseStats';
import parseBatter from './parseBatter';
import parsePitcher from './parsePitcher';

const parsers: {
  [position: string]: (games: PlayerSeasonGame[]) => PlayerGamesLogTable;
} = {
  Batter: parseBatter,
  Pitcher: parsePitcher,
};

const getBaseballPlayerPosition = (playerSeasonGames: PlayerSeasonGame[]): string => {
  if (typeof playerSeasonGames[0].playerActivity?.hitting !== 'undefined') {
    return 'Batter';
  }

  if (typeof playerSeasonGames[0].playerActivity?.pitching !== 'undefined') {
    return 'Pitcher';
  }

  return undefined;
};

const parseFootballGames = (
  playerSeasonGames: PlayerSeasonGame[],
): PlayerGamesLogTable => {
  if (playerSeasonGames.length === 0) return [];

  const position = getBaseballPlayerPosition(playerSeasonGames);

  const baseStats = parseBaseStats(playerSeasonGames);
  if (!position) return baseStats;

  const parser = parsers[position] ?? (() => []);

  return [...baseStats, ...parser(playerSeasonGames)];
};

export default parseFootballGames;
