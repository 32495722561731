import { format } from 'date-fns';
import { Badge, Tooltip } from '@mantine/core';

import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const getOpp = (playerSeasonGame: PlayerSeasonGame): string => {
  const playerTeamId = playerSeasonGame.teamId;
  const homeTeam = playerSeasonGame.gameAttributes.home;
  const awayTeam = playerSeasonGame.gameAttributes.away;

  if (!playerTeamId || !homeTeam || !awayTeam) {
    return '-';
  }

  return homeTeam.id === playerTeamId ? awayTeam.alias : `@${homeTeam.alias}`;
};

const parseBaseStats = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    date: {
      label: 'DATE',
      values: [],
    },
    opp: {
      label: 'OPP',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    const { gameStartDate, season } = playerSeasonGame;

    const isPreseason = !!season?.name?.includes('PRE');
    table.date.values.push(
      <>
        {format(new Date(gameStartDate), 'M/d/yy')}
        {isPreseason ? (
          <>
            &nbsp;
            <Tooltip label="Preseason" position="top" withArrow>
              <Badge color="dark" variant="filled" size="xs">
                P
              </Badge>
            </Tooltip>
          </>
        ) : null}
      </>
    );
    table.opp.values.push(getOpp(playerSeasonGame));
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseBaseStats;
