import Cookies from 'js-cookie';
import { useMemo } from 'react';

import { jwtDecodeSafe } from '~/utils/jwt';

type AccessTokenData = {
  role: string;
  'cognito:username': string;
  auth_time: number;
  exp: number;
  iat: number;
  sub: string; // old user id
  id: string; // user id
};

const useIsUserAuthorised = () => {
  const accessToken = Cookies.get('accessToken');

  const isUserAuthorised = useMemo(() => {
    if (!accessToken) {
      return false;
    }

    const jwt = jwtDecodeSafe<AccessTokenData>(accessToken);
    const userId = jwt.id ?? jwt.sub;

    return userId && jwt?.exp && jwt?.exp > Date.now() / 1000;
  }, [accessToken]);

  return isUserAuthorised;
};

export default useIsUserAuthorised;
