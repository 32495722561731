import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { STALE_TIME } from '~/domains/contest/constants/query';
import { getPropsByIds } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';
import useQueryParamPicks from '../context/BoardContext/useQueryParamPicks';
import { parsePropToComponentProp } from '../utils/parsePropToComponentProp';
import { useQuickPicksSettings } from './useQuickPicksSettings';

function useQuickPicksGetPropsById() {
  const props = useQueryParamPicks();
  const settingsQuery = useQuickPicksSettings();
  const isEnabled = useMemo(() => !!props && !!settingsQuery.data, [props, settingsQuery.data]);

  const propsIds = props ? Object.keys(props).join(',') : '';
  const { data, isLoading } = useQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_GET_PROPS_BY_IDS(),
    queryFn: () => getPropsByIds(propsIds, settingsQuery.data?.state_config.disallowed ?? []),
    staleTime: STALE_TIME,
    enabled: isEnabled,
  });

  const propsFromQueryParams = useMemo(
    () =>
      data?.data.map((prop) => ({
        ...parsePropToComponentProp(prop),
        direction: props[prop.id],
      })),
    [data, props]
  );

  return { propsFromQueryParams, isLoading, isEnabled };
}

export default useQuickPicksGetPropsById;
