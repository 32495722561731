import NextImage from 'next/image';
import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';

/**
 * This prevents from repetitive loading of 404 images.
 * Should not be used in prod, but it's useful for development.
 */
const failedImages = new Set<string>();

function Image({
  fallbackComponent,
  fallbackImageUrl,
  ...rest
}: ComponentProps<typeof NextImage> & {
  fallbackComponent?: JSX.Element;
  fallbackImageUrl?: string;
}) {
  const [isError, setIsError] = useState(failedImages.has(rest.src as string) || !rest.src);

  // In case the image src is changed, we need to reset the error state
  useEffect(() => {
    if (isError && !failedImages.has(rest.src as string)) {
      setIsError(false);
    }
  }, [isError, rest.src]);

  if (isError && (fallbackImageUrl || fallbackComponent)) {
    return fallbackImageUrl ? <Image {...rest} src={fallbackImageUrl} /> : fallbackComponent;
  }

  return (
    <NextImage
      {...rest}
      quality={100}
      priority
      onError={() => {
        failedImages.add(rest.src as string);
        setIsError(true);
      }}
    />
  );
}

export default Image;
