import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const parseBatter = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    ab: {
      label: 'AB',
      values: [],
    },
    hits: {
      label: 'HITS',
      values: [],
    },
    runs: {
      label: 'RUNS',
      values: [],
    },
    rbis: {
      label: 'RBIS',
      values: [],
    },
    tb: {
      label: 'TB',
      values: [],
    },
    hr: {
      label: 'HR',
      values: [],
    },
    so: {
      label: 'SO',
      values: [],
    },
    bbb: {
      label: 'B BB',
      values: [],
    },
    singles: {
      label: '1B',
      values: [],
    },
    doubles: {
      label: '2B',
      values: [],
    },
    triples: {
      label: '3B',
      values: [],
    },
    sb: {
      label: 'SB',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    const { playerActivity } = playerSeasonGame;

    table.ab.values.push(playerActivity.hitting?.at_bats ?? '-');
    table.hits.values.push(playerActivity.hitting?.hits ?? '-');
    table.runs.values.push(playerActivity.hitting?.runs ?? '-');
    table.rbis.values.push(playerActivity.hitting?.rbi ?? '-');
    table.tb.values.push(playerActivity.hitting?.total_bases ?? '-');
    table.hr.values.push(playerActivity.hitting?.home_runs ?? '-');
    table.so.values.push(playerActivity.hitting?.total_strikeouts ?? '-');
    table.bbb.values.push(playerActivity.hitting?.walks ?? '-');
    table.singles.values.push(playerActivity.hitting?.singles ?? '-');
    table.doubles.values.push(playerActivity.hitting?.doubles ?? '-');
    table.triples.values.push(playerActivity.hitting?.triples ?? '-');
    table.sb.values.push(playerActivity.hitting?.stolen_bases ?? '-');
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseBatter;
