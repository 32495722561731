import { useMutation } from '@tanstack/react-query';

import type { ValidateSlipPayload } from '../../services/interfaces/propsService/api';
import { postValidateSlip } from '../../services/props.service.api';

const useQuickPicksValidateSlipMutation = () =>
  useMutation({
    mutationFn: (payload: ValidateSlipPayload) => postValidateSlip(payload),
  });

export default useQuickPicksValidateSlipMutation;
