import { Sport } from '@betterpool/api-types/contests-service';

import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type { PlayerGamesLogTable } from '../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

import parseFootballGames from './parseFootballGames/parseFootballGames';
import parseGolfGames from './parseGolfGames';
import parseBasketballGames from './parseBasketballGames';
import parseBaseballGames from './parseBaseballGames/parseBaseballGames';

const parsers: {
  [sport: string]: (games: PlayerSeasonGame[], position?: string) => PlayerGamesLogTable;
} = {
  [Sport.FOOTBALL]: parseFootballGames,
  [Sport.GOLF]: parseGolfGames,
  [Sport.BASKETBALL]: parseBasketballGames,
  [Sport.BASEBALL]: parseBaseballGames,
};

const parsePlayerGamesLog = (
  playerGamesLogGames: PlayerSeasonGame[],
  sport: Sport,
  position?: string
): PlayerGamesLogTable => {
  const parser = parsers[sport] ?? (() => []);

  return parser(playerGamesLogGames, position);
};

export default parsePlayerGamesLog;
