import { useCallback, useEffect, useMemo, useState } from 'react';

import { reportEvent } from '~/domains/analytics';
import type { UserDirection } from '../../../services/interfaces/propsService/api';
import useStoredPicks from '../../components/Board/PicksDrawer/useStoredPicks';
import useQuickPicksGetPropsById from '../../hooks/useQuickPicksGetPropsById';
import type { PropPickProps } from '../../types/propPickProps.type';

function usePicks() {
  const {
    propsFromQueryParams,
    isLoading: isLoadingQueryParamProps,
    isEnabled,
  } = useQuickPicksGetPropsById();
  const { getStoredPicks, setStoredPicks, clearStoredPicks, clearStoredAnonPicks } =
    useStoredPicks();
  const [selectedPicks, setSelectedPicks] = useState<PropPickProps[]>(
    isEnabled ? propsFromQueryParams : getStoredPicks() ?? []
  );

  const addPick = useCallback((pick: PropPickProps) => {
    setSelectedPicks((prev) => [...prev, pick]);
  }, []);

  const removePick = useCallback((pickId: string) => {
    setSelectedPicks((prev) => prev.filter((p) => p.id !== pickId));
  }, []);

  const editPick = useCallback((pickId: string, direction: UserDirection) => {
    setSelectedPicks((prev) => prev.map((p) => (p.id === pickId ? { ...p, direction } : p)));
  }, []);

  const resetPicks = useCallback(() => {
    setSelectedPicks([]);
    clearStoredAnonPicks();
  }, [clearStoredAnonPicks]);

  useEffect(() => {
    if (isEnabled && !isLoadingQueryParamProps && propsFromQueryParams?.length > 0) {
      setSelectedPicks(propsFromQueryParams);
      reportEvent('QuickPicks > Prefill Slip', {
        numberOfPicks: propsFromQueryParams.length,
      });
    }
  }, [isEnabled, isLoadingQueryParamProps, propsFromQueryParams?.length]);

  useEffect(() => {
    if (selectedPicks.length === 0) {
      clearStoredPicks();
    } else {
      setStoredPicks(selectedPicks);
    }
  }, [clearStoredPicks, selectedPicks, setStoredPicks]);

  return useMemo(
    () => ({
      value: selectedPicks,
      add: addPick,
      remove: removePick,
      edit: editPick,
      set: setSelectedPicks,
      reset: resetPicks,
    }),
    [addPick, editPick, removePick, resetPicks, selectedPicks]
  );
}

export default usePicks;
