import { format } from 'date-fns';

import { getTeamOrPlayerImageUrl } from '~/domains/common/utils/getTeamOrPlayerImageUrl';

import type { APIPropChartData } from '../../services/interfaces/propsService/api';
import type { TPropExpanded, TUserProp } from '../../services/interfaces/propsService/parsed/props';
import type { PropPickChartProps, PropPickProps } from '../types/propPickProps.type';

import { getPlayerLineup } from './parseGroupSlip';

export function parseChartData(chartData: APIPropChartData, line?: number): PropPickChartProps {
  return {
    chartData: chartData.data.map((item) => ({
      label: new Date(item.date).toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
      }),
      secondaryLabel: item.label,
      value: item.score,
    })),
    lastXAvg: (
      chartData.data.reduce((acc, item) => acc + item.score, 0) / chartData.data.length
    ).toFixed(1),
    ticks: Array.from({ length: chartData.num_ticks }, (_, i) => i * chartData.tick_step),
    tickCount: chartData.num_ticks,
    ...(line ? { referenceLineValue: line } : {}),
  };
}

export function parsePropToComponentProp(prop: TPropExpanded | TUserProp): PropPickProps {
  const idForPlayerImage = prop.use_team_image ? prop.team_id : prop.entity_id;

  return {
    id: prop.id,
    playerId: prop.entity_id,
    leagueImage: getTeamOrPlayerImageUrl(prop.league, prop.team_id),
    playerImage: getTeamOrPlayerImageUrl(prop.league, idForPlayerImage),
    playerName: `${prop.entity?.player_details.first_name.slice(0, 1)}. ${prop.entity?.player_details.last_name}`,
    position: prop.entity?.player_details?.position,
    awayTeam: {
      alias: prop.game?.away.alias ?? 'Away Team',
      id: prop.game?.away.id ?? 'team-away',
      points: prop.game?.away.points,
      lineup: prop.game?.away.lineup,
    },
    homeTeam: {
      alias: prop.game?.home.alias ?? 'Home Team',
      id: prop.game?.home.id ?? 'team-home',
      points: prop.game?.home.points,
      lineup: prop.game?.home.lineup,
    },
    playerTeamId: prop.team_id,
    startDate: format(prop.game_start, 'M/dd h:mm a'),
    line: {
      value: prop.line,
      label: prop.type_display,
      type: prop.type,
    },
    direction: (prop as TUserProp).direction,
    ...(prop.chart_data ? { chartProps: parseChartData(prop.chart_data, prop.line) } : {}),
    useTeamAlias: prop.use_team_image,
    teamAlias: prop.team?.alias,
    teamColor: prop.team?.primary_color,
    ...(prop.game.league === 'mlb'
      ? {
          playerLineup: getPlayerLineup(
            prop.game.home.lineup,
            prop.game.away.lineup,
            prop.entity_id
          ),
        }
      : {}),
  };
}
