import type { ComponentProps } from 'react';
import { useCallback } from 'react';

import { reportQuickPicksPicksReuse } from '../../../analyticsEvents';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';

import { useQueryClient } from '@tanstack/react-query';
import QuickPickQueryKeys from '../../../constants/query';
import type EntrySubmitted from './EntrySubmitted/EntrySubmitted';

function useEntrySubmitted() {
  const { picks, picksSuccess, selectedBoost, setSelectedBoostId } = useBoardContext();
  const queryClient = useQueryClient();

  const handleClose = useCallback(() => {
    picksSuccess.close();
    queryClient.invalidateQueries({ queryKey: QuickPickQueryKeys.QUICK_PICKS_BOOSTS() });
    setSelectedBoostId(null);
  }, [picksSuccess, setSelectedBoostId]);

  const handlePicksReuse = useCallback(() => {
    reportQuickPicksPicksReuse({
      numberOfPicks: picksSuccess.payload?.picks.length,
    });
    picks.set(picksSuccess.payload?.picks);
    handleClose();
  }, [picks, picksSuccess, setSelectedBoostId]);

  return {
    entrySubmittedProps: {
      isOpened: picksSuccess.isOpen,
      onClose: handleClose,
      picks: picksSuccess.payload?.picks ?? [],
      groupSize: picksSuccess.payload?.groupSize ?? 0,
      entryAmount: picksSuccess.payload?.entryAmount ?? 0,
      potentialPayoutAmount: picksSuccess.payload?.potentialPayoutAmount ?? 0,
      selectedBoost,
      onPicksReuse: handlePicksReuse,
    } satisfies ComponentProps<typeof EntrySubmitted>,
  };
}

export default useEntrySubmitted;
