import { useQuickPicksSettings } from '~/domains/contest/domains/quickPick/hooks/useQuickPicksSettings';

export const useQuickPickLocationAllowed = () => {
  // Using Props settings (they use CloudFlare geoIP headers) to check if the user is allowed to pick single stat props
  const settingsQuery = useQuickPicksSettings();

  const isSingleStatPropsDisallowed =
    settingsQuery.data?.state_config?.disallowed?.includes('single_stat_props');
  const isQPdisallowed =
    settingsQuery.data?.state_config?.disallowed?.includes('props-peer-to-peer');
  const state = settingsQuery.data?.state_config?.state;

  return {
    isAllowed: !isQPdisallowed,
    isSingleStatPropsAllowed: !isSingleStatPropsDisallowed,
    state,
  };
};
