import { useDisclosure } from '@mantine/hooks';
import type { ComponentProps, ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import type EntryPicksChangedAlert from '../../components/Board/PicksDrawer/EntryPicksChangedAlert/EntryPicksChangedAlert';
import type EntrySubmitted from '../../components/Board/PicksDrawer/EntrySubmitted/EntrySubmitted';

import { useQueryClient } from '@tanstack/react-query';
import { FEATURE } from '~/common/enums/feature.enum';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import type { APIGetBoostsResponse } from '../../../services/interfaces/propsService/api';
import type { PublicBoost } from '../../../services/interfaces/propsService/api/boosts';
import { reportQuickPicksPicksAlert } from '../../analyticsEvents';
import QuickPickQueryKeys from '../../constants/query';
import useQuickPicksBoosts from '../../hooks/useQuickPicksPromos';
import useQuickPicksValidateSlipMutation from '../../hooks/useQuickPicksValidateSlipMutation';
import { parsePropToComponentProp } from '../../utils/parsePropToComponentProp';
import useError from './useError';
import useFilters from './useFilters';
import usePicks from './usePicks';

type PicksSuccessPayload = {
  picks: ComponentProps<typeof EntrySubmitted>['picks'];
  entryAmount: number;
  potentialPayoutAmount: number;
  groupSize: number;
};

interface BoardContextType {
  picks: ReturnType<typeof usePicks>;
  filters: ReturnType<typeof useFilters>;
  picksDrawer: ReturnType<typeof useDisclosure>[1] & {
    isOpen: boolean;
  };
  picksAlert: ReturnType<typeof useDisclosure>[1] & {
    isOpen: boolean;
    payload?: ComponentProps<typeof EntryPicksChangedAlert>['picks'];
    setPayload: React.Dispatch<
      React.SetStateAction<ComponentProps<typeof EntryPicksChangedAlert>['picks']>
    >;
  };
  picksSuccess: ReturnType<typeof useDisclosure>[1] & {
    isOpen: boolean;
    payload?: PicksSuccessPayload;
    setPayload: React.Dispatch<React.SetStateAction<React.SetStateAction<PicksSuccessPayload>>>;
  };
  error: ReturnType<typeof useError>;
  selectedBoostId: string | null;
  setSelectedBoostId: React.Dispatch<React.SetStateAction<string>>;
  selectedBoost: PublicBoost | null;
  boosts: PublicBoost[];
  boostsData: UseInfiniteQueryResult<APIGetBoostsResponse, unknown>;
  totalBoostsCount: number;
}

const BoardContext = createContext<BoardContextType | undefined>(undefined);

function BoardProvider({ children }: { children: ReactNode }) {
  const [isPicksDrawerOpen, picksDrawerHandlers] = useDisclosure();
  const [isPicksAlertOpen, picksAlertHandlers] = useDisclosure();
  const [picksDrawerPayload, setPicksDrawerPayload] =
    useState<ComponentProps<typeof EntryPicksChangedAlert>['picks']>();
  const [picksSuccessPayload, setPicksSuccessPayload] = useState<PicksSuccessPayload>();
  const picks = usePicks();
  const [isPicksSuccessOpen, picksSuccessHandlers] = useDisclosure();
  const [selectedBoostId, setSelectedBoostId] = useState<string | null>(null);
  const filters = useFilters();
  const error = useError();
  const isQPBoostEnabled = useIsFeatureEnabled(FEATURE.ENABLE_QP_BOOSTS);
  const boostsData = useQuickPicksBoosts({ enabled: isQPBoostEnabled, refetchInterval: 30000 });
  const queryClient = useQueryClient();
  const validateSlip = useQuickPicksValidateSlipMutation();

  const totalBoostsCount = useMemo(() => {
    if (!boostsData?.data?.pages?.length) {
      return 0;
    }

    return boostsData.data.pages[0].total;
  }, [boostsData.data]);

  const handleClosePicksSuccess = useCallback(() => {
    setSelectedBoostId(null);
    queryClient.invalidateQueries({ queryKey: [QuickPickQueryKeys.QUICK_PICKS_BOOSTS] });
    picksSuccessHandlers.close();
  }, [picksSuccessHandlers, setSelectedBoostId, queryClient]);

  const handleShowAlert = useCallback(
    (alertResponse) => {
      const propsChanged = Object.keys(alertResponse.data.changes).length;

      reportQuickPicksPicksAlert({
        numberOfChanges: alertResponse.data.inactive_prop_ids.length,
        propChanged: !!propsChanged,
        propRemoved: alertResponse.data.inactive_prop_ids.length > propsChanged,
      });

      picksDrawerHandlers.close();
      setPicksDrawerPayload(
        alertResponse.data.inactive_prop_ids.map((pickId) => {
          const pick = picks.value.find((p) => p.id === pickId);
          const changedPick = alertResponse.data.changes[pickId];

          return {
            oldProp: pick,
            newProp: changedPick ? parsePropToComponentProp(changedPick) : undefined,
          };
        })
      );
      picksAlertHandlers.open();
    },
    [picksAlertHandlers.open, picksAlertHandlers, picksDrawerHandlers.close, picks.value]
  );

  const handleTogglePicksDrawer = useCallback(async () => {
    if (!isPicksDrawerOpen) {
      const response = await validateSlip.mutateAsync({
        ids: picks.value.map((pick) => pick.id),
      });

      if (response.status === 'error') {
        handleShowAlert(response);
        return;
      }
    }
    picksDrawerHandlers.toggle();
  }, [isPicksDrawerOpen, picksDrawerHandlers, error.error, handleShowAlert]);

  const boosts = useMemo(() => {
    if (!boostsData?.data?.pages?.length) {
      return [];
    }

    return boostsData.data.pages.flatMap((page) => page.data);
  }, [boostsData.data]);

  const contextValue = useMemo(
    () =>
      ({
        picks,
        filters,
        picksDrawer: {
          isOpen: isPicksDrawerOpen,
          ...picksDrawerHandlers,
          toggle: handleTogglePicksDrawer,
        },
        picksAlert: {
          isOpen: isPicksAlertOpen,
          payload: picksDrawerPayload,
          setPayload: setPicksDrawerPayload,
          ...picksAlertHandlers,
        },
        picksSuccess: {
          isOpen: isPicksSuccessOpen,
          ...picksSuccessHandlers,
          payload: picksSuccessPayload,
          setPayload: setPicksSuccessPayload,
          close: handleClosePicksSuccess,
        },
        error,
        selectedBoostId,
        setSelectedBoostId,
        selectedBoost: boosts.find((boost) => boost.id === selectedBoostId) ?? null,
        boosts,
        boostsData,
        totalBoostsCount,
      }) satisfies BoardContextType,
    [
      picks,
      filters,
      isPicksDrawerOpen,
      picksDrawerHandlers,
      isPicksAlertOpen,
      picksDrawerPayload,
      picksAlertHandlers,
      isPicksSuccessOpen,
      picksSuccessHandlers,
      picksSuccessPayload,
      error,
      selectedBoostId,
      setSelectedBoostId,
      boosts,
      boostsData,
      totalBoostsCount,
    ]
  );

  return <BoardContext.Provider value={contextValue}>{children}</BoardContext.Provider>;
}

function useBoardContext() {
  const context = useContext(BoardContext);
  if (context === undefined) {
    throw new Error('useBoardContext must be used within a BoardProvider');
  }
  return context;
}

export { BoardProvider, useBoardContext };
