import { useRouter } from 'next/router';

type QueryParamPicks = {
  [key: string]: 'over' | 'under';
};

function useQueryParamPicks(): QueryParamPicks | null {
  const router = useRouter();

  const { props } = router.query;
  if (typeof props === 'string') {
    const parsedProps = props.split(',').reduce((acc, prop) => {
      const [key, value] = prop.split('-');
      return { ...acc, [key]: value === 'o' ? 'over' : 'under' };
    }, {});
    return parsedProps;
  }
  return null;
}

export default useQueryParamPicks;
