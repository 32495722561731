import { useQuery } from '@tanstack/react-query';

import { STALE_TIME } from '~/domains/contest/constants/query';
import { useLocation } from '~/hooks/useLocation';

import { getSettings } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';

export const useQuickPicksSettings = () => {
  // Refetch settings when location state changes (we have a precise token now)
  const { locationState } = useLocation();

  // @TODO: Return the parsed settings, not whole data object
  return useQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_SETTINGS(locationState),
    queryFn: () => getSettings(),
    staleTime: STALE_TIME,
    refetchInterval: 30 * 60 * 1000,
  });
};
