import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { STALE_TIME } from '~/domains/contest/constants/query';

import type { PropsFiltersType, PublicGame } from '../../services/interfaces/propsService/api';
import { getFilters } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';
import { useQuickPicksSettings } from './useQuickPicksSettings';

export type FlattenedType = PropsFiltersType & {
  leagueId: string;
};

export const useQuickPicksFilters = () => {
  const settingsQuery = useQuickPicksSettings();

  const { data, isLoading, error } = useQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_FILTERS(),
    queryFn: () => getFilters(settingsQuery.data?.state_config.disallowed ?? []),
    staleTime: STALE_TIME,
    enabled: !!settingsQuery.data,
  });

  const flattenedTypes: FlattenedType[] = useMemo(
    () =>
      data?.filters.leagues.flatMap((league) =>
        league.types.map((type) => ({
          ...type,
          leagueId: league.id,
        }))
      ) || [],
    [data]
  );

  const gamesByLeague = useMemo(
    () =>
      data?.filters.leagues.reduce(
        (acc, league) => {
          acc[league.id] = league.games;
          return acc;
        },
        {} as Record<string, PublicGame[]>
      ),
    [data]
  );

  return { data, isLoading, error, flattenedTypes, gamesByLeague };
};
