import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import type { Sport } from '@betterpool/api-types/contests-service';

import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';
import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

import type { PlayerGamesLogTableColumn } from '../../../game/domains/common/dfs/types/gamesLogTable';
import type { PlayerGroupedStats } from '../../../game/domains/common/dfs/types/player';
import GamecenterPlayerDetailModalContainer from '../../../gamecenter/domains/player/components/GamecenterPlayerDetail/GamecenterPlayerDetailModalContainer';
import type { GetPropResponse } from '../../../services/interfaces/propsService/api';
import type { PropPickProps } from '../../types/propPickProps.type';

import PlayerCard from './PlayerCard';
import PlayerCardHeader from './PlayerCardHeader';

export type PlayerCardModalProps = {
  opened: boolean;
  onClose: () => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isLoading: boolean;
  player: GetPropResponse;
  groupedStats: PlayerGroupedStats;
  playerSeasonData: UseInfiniteQueryResult<PaginatedData<PlayerSeasonGame>> & {
    items: PlayerGamesLogTableColumn[];
  };
  handlePick: (pick: PropPickProps) => void;
  activePropData: PropPickProps;
  otherPropsData: PropPickProps[];
  selectedPick: PropPickProps;
  sport: Sport;
};

function PlayerCardModal({
  opened,
  onClose,
  activeTab,
  setActiveTab,
  isLoading,
  player,
  groupedStats,
  playerSeasonData,
  handlePick,
  activePropData,
  otherPropsData,
  selectedPick,
  sport,
}: PlayerCardModalProps) {
  return (
    <GamecenterPlayerDetailModalContainer
      isOpen={opened}
      onClose={onClose}
      header={<PlayerCardHeader player={player} isLoading={isLoading} />}
    >
      <PlayerCard
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isLoading={isLoading}
        groupedStats={groupedStats}
        playerSeasonData={playerSeasonData}
        handlePick={handlePick}
        activePropData={activePropData}
        otherPropsData={otherPropsData}
        selectedPick={selectedPick}
        sport={sport}
      />
    </GamecenterPlayerDetailModalContainer>
  );
}

export default PlayerCardModal;
