import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const parsePitcher = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    ip: {
      label: 'IP',
      values: [],
    },
    k: {
      label: 'K',
      values: [],
    },
    outs: {
      label: 'OUTS',
      values: [],
    },
    ha: {
      label: 'HA',
      values: [],
    },
    er: {
      label: 'ER',
      values: [],
    },
    pbb: {
      label: 'P BB',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    const { playerActivity } = playerSeasonGame;
    table.ip.values.push(playerActivity.pitching?.innings_pitched ?? '-');
    table.k.values.push(playerActivity.pitching?.strikeouts ?? '-');
    table.outs.values.push(playerActivity.pitching?.total_outs ?? '-');
    table.ha.values.push(playerActivity.pitching?.hits_allowed ?? '-');
    table.er.values.push(playerActivity.pitching?.earned_run_average ?? '-');
    table.pbb.values.push(playerActivity.pitching?.base_on_balls ?? '-');
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parsePitcher;
