import { createStyles } from '@mantine/core';

import EntryPicksChangedAlert from './EntryPicksChangedAlert/EntryPicksChangedAlert';
import EntrySubmitted from './EntrySubmitted/EntrySubmitted';
import PicksDrawer from './PicksDrawer';
import useEntryPicksChangedAlerts from './useEntryPicksChangedAlerts';
import useEntrySubmitted from './useEntrySubmitted';
import usePicksDrawer from './usePicksDrawer';

const useStyles = createStyles({
  placeholder: {
    height: 64,
  },
});

function ConnectedPicksDrawer({ isPromo }: { isPromo?: boolean }) {
  const { classes } = useStyles();
  const { entrySubmittedProps } = useEntrySubmitted();
  const { isSettingsDataMissing, picksDrawerProps } = usePicksDrawer();
  const entryChangedAlertProps = useEntryPicksChangedAlerts();

  if (isSettingsDataMissing) {
    return <div className={classes.placeholder} />;
  }

  return (
    <>
      <PicksDrawer isPromo={isPromo} {...picksDrawerProps} />
      <EntrySubmitted {...entrySubmittedProps} />
      <EntryPicksChangedAlert {...entryChangedAlertProps} />
    </>
  );
}

export default ConnectedPicksDrawer;
