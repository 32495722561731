import { createStyles, Loader } from '@mantine/core';
import { IconArrowsSplit, IconInfoCircle } from '@tabler/icons-react';
import Color from 'color';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';

import { CurrencyConvertor } from '~/components/utils/formatters';
import { Tooltip } from '~/domains/common/components/Tooltip/Tooltip';
import { Routes } from '~/domains/common/constants/routes';
import { Button, ButtonLink, UnstyledButton } from '~/domains/design-system/Button';
import { Text, textStyles } from '~/domains/design-system/Text';
import { useTutorialContext } from '~/domains/tutorials/TutorialModalContext';

import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import BoostsCTA from '../../Boosts/BoostsCTA';

const useStyles = createStyles((theme) => ({
  actionPanel: {
    background: theme.colors.gray2[7],
    borderRadius: `${theme.other.spacing._16}px ${theme.other.spacing._16}px 0 0`,
    height: '100%',
    padding: theme.other.spacing._16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.other.spacing._8,
  },
  actionPanelTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._8,
  },
  actionPanelBottom: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._16,
  },
  actionPanelTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...textStyles['headline-medium'],
    color: theme.white,
  },
  entryFeeOptions: {
    display: 'flex',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    gap: theme.other.spacing._8,
  },
  entryFeeOption: {
    height: 48,
    minWidth: 60,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.gray2[4],
    color: theme.white,
    borderRadius: theme.other.spacing._4,
    ...textStyles['headline-small'],

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },

    '&.selected': {
      background: theme.white,
      color: theme.colors.gray2[6],
    },
  },
  winMultiplier: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.other.spacing._16,
    background: Color(theme.colors.gray2[4]).alpha(0.2).string(),
    border: `1px solid ${theme.colors.gray2[4]}`,
    borderRadius: theme.other.spacing._4,
    ...textStyles['subhead-medium'],
    color: theme.white,
    height: 48,
  },
  winMultiplierLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  winMultiplierRight: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._8,
  },
  footNote: {
    ...textStyles['body-small'],
    color: theme.colors.gray2[1],
    textAlign: 'center',
    marginTop: theme.other.spacing._8,
    marginBottom: theme.other.spacing._8,
    maxWidth: 400,
    alignSelf: 'center',
    '& a.link': {
      ...textStyles['body-small'],
      textDecoration: 'underline',
      display: 'inline',
      paddingLeft: theme.other.spacing._2,
    },
  },
  infoTooltipContent: {
    ...textStyles['body-small'],
    color: theme.colors.gray2[6],
    maxWidth: 300,
  },
  balance: {
    ...textStyles['body-medium'],
    color: theme.colors.gray2[1],

    '& strong': {
      ...textStyles['subhead-small'],
      color: theme.white,
    },
  },
  balanceLoader: {
    verticalAlign: 'bottom',
  },
  submit: {
    width: '100%',
  },
  splitEntryEligible: {
    height: 48,
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.other.spacing._4,
    ...textStyles['subhead-medium'],
    color: theme.colors.gray2[1],
    transition: 'height 500ms ease-in-out, opacity 500ms ease-in-out',
    '&.hidden': {
      height: 0,
      opacity: 0,
    },
  },
  boostedPayout: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._4,
  },
  strikethrough: {
    textDecoration: 'line-through',
  },
}));

function PicksDrawerActionsPanel({
  balance,
  entryAmounts,
  isBalanceError,
  isBalanceHidden,
  isBalanceLoading,
  isSubmitDisabled,
  isPromo,
  potentialPayoutAmount,
  selectedEntryAmount,
  submitLabel,
  onEntryAmountChange,
  onSubmit,
  multiplier,
  isSplitEntryEligible,
  isBoostsAvailableModalOpen,
  setIsBoostsAvailableModalOpen,
  picksTotal,
}: {
  balance: number;
  entryAmounts: number[];
  isBalanceError?: boolean;
  isBalanceHidden?: boolean;
  isBalanceLoading?: boolean;
  isSubmitDisabled?: boolean;
  isPromo?: boolean;
  potentialPayoutAmount: number;
  selectedEntryAmount: number;
  submitLabel: string;
  onEntryAmountChange: (amount: number) => void;
  onSubmit: () => void;
  multiplier: number;
  isSplitEntryEligible?: boolean;
  isBoostsAvailableModalOpen: boolean;
  setIsBoostsAvailableModalOpen: (open: boolean) => void;
  picksTotal: number;
}) {
  const { t } = useTranslation('contest');
  const { classes, cx, theme } = useStyles();
  const { setIsOpen } = useTutorialContext();
  const { selectedBoost, boosts } = useBoardContext();
  const boostedPayout =
    selectedBoost?.bet_options?.[picksTotal]?.[selectedEntryAmount]?.total_payout;

  const handleInfoClick = useCallback(() => {
    setIsOpen({
      isOpen: true,
      onClose: () => {
        setIsOpen({ isOpen: false });
      },
    });
  }, [setIsOpen]);

  return (
    <div className={classes.actionPanel}>
      <div className={classes.actionPanelTop}>
        <div className={classes.actionPanelTitle}>
          {t('quickPick.board.picksDrawer.actionsPanel.selectEntryFee')}
          {!isBalanceHidden && (
            <div className={classes.balance}>
              {(() => {
                if (isBalanceError && !isBalanceLoading) {
                  return t('quickPick.board.picksDrawer.actionsPanel.balanceError');
                }

                return (
                  <>
                    {t('quickPick.board.picksDrawer.actionsPanel.balance')}&nbsp;
                    {isBalanceLoading ? (
                      <Loader size={16} className={classes.balanceLoader} />
                    ) : (
                      <strong>{CurrencyConvertor(balance)}</strong>
                    )}
                  </>
                );
              })()}
            </div>
          )}
        </div>
        <div className={classes.entryFeeOptions}>
          {entryAmounts.map((amount) => (
            <UnstyledButton
              key={amount}
              className={cx(classes.entryFeeOption, { selected: selectedEntryAmount === amount })}
              onClick={() => onEntryAmountChange(amount)}
            >
              {CurrencyConvertor(amount)}
            </UnstyledButton>
          ))}
        </div>
        <div className={classes.winMultiplier}>
          <div className={classes.winMultiplierLeft}>
            <Tooltip
              withinPortal
              withArrow
              multiline
              position="bottom"
              labels={[
                {
                  label: (
                    <div className={classes.infoTooltipContent}>
                      {t('quickPick.board.picksDrawer.actionsPanel.footNoteInfoTooltip')}
                    </div>
                  ),
                },
              ]}
            >
              <IconInfoCircle size={16} color={theme.colors.gray2[3]} cursor="pointer" />
            </Tooltip>
            &nbsp;
            {t('quickPick.board.picksDrawer.actionsPanel.maxPayout')}&nbsp;
          </div>
          <div className={classes.winMultiplierRight}>
            {multiplier > 0 && (
              <Text variant="subhead-medium" color={theme.colors.gray2[3]}>
                {multiplier}x:
              </Text>
            )}
            {boostedPayout ? (
              <div className={classes.boostedPayout}>
                <Text
                  className={classes.strikethrough}
                  variant="subhead-medium"
                  color={theme.colors.gray2[4]}
                >
                  {CurrencyConvertor(potentialPayoutAmount)}
                </Text>
                <Text variant="subhead-medium" color={theme.colors.green[3]}>
                  {CurrencyConvertor(boostedPayout)}
                </Text>
              </div>
            ) : (
              <Text variant="subhead-medium" color={theme.colors.green[3]}>
                {CurrencyConvertor(potentialPayoutAmount)}
              </Text>
            )}
          </div>
        </div>
        {boosts.length > 0 && (
          <BoostsCTA
            isBoostsAvailableModalOpen={isBoostsAvailableModalOpen}
            setIsBoostsAvailableModalOpen={setIsBoostsAvailableModalOpen}
          />
        )}
        <div className={cx(classes.splitEntryEligible, { hidden: !isSplitEntryEligible })}>
          <IconInfoCircle
            size={16}
            color={theme.colors.gray2[3]}
            cursor="pointer"
            onClick={handleInfoClick}
          />
          {t('quickPick.board.picksDrawer.actionsPanel.splitEntryEligible')}
          <IconArrowsSplit stroke={2} size={16} color={theme.colors.splash[3]} />
        </div>
        <div className={classes.footNote}>
          {t('quickPick.board.picksDrawer.actionsPanel.footNote')}
          <ButtonLink
            className="link"
            mode="linkButton"
            size="small"
            href={Routes.quickPickRules()}
            target="_blank"
          >
            {t('quickPick.board.picksDrawer.actionsPanel.footNoteRulesLink')}
          </ButtonLink>
        </div>
      </div>
      <div className={classes.actionPanelBottom}>
        {isPromo && (
          <ButtonLink
            variant="primary-stroke"
            size="medium"
            type="text"
            href={Routes.quickPicksBoard()}
            fullWidth
          >
            {t('quickPick.board.picksDrawer.actionsPanel.viewAllLabel')}
          </ButtonLink>
        )}
        <Button
          variant="primary-fill"
          size="medium"
          fullWidth
          onClick={onSubmit}
          disabled={isSubmitDisabled}
        >
          {submitLabel}
        </Button>
      </div>
    </div>
  );
}

export default PicksDrawerActionsPanel;
